/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useState } from 'react';
import {
  Box,
  Button,
  Paper,
  TextField,
  Typography,
  List,
  IconButton,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '@ink-ai/portal/reducers';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigationListener } from '../../components/hooks/useNavigationListener';
import RichTextEditor from './RichTextEditor';
import { identity } from 'lodash-es';
import { writingInstructionActions } from '@ink-ai/portal/reducers/writing-instruction';
import { WritingInstructionApi } from '@ink-ai/insight-service-sdk';
import { getApi } from '@ink-ai/portal/common/requestHelper';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

export const WritingInstructionEditor: React.FC = () => {
  const navigate = useNavigate();
  const instructionState = useSelector(
    (state: RootState) => state.writingInstruction,
  );
  const dispatch = useDispatch<any>();
  const [loadingSave, setLoadingSave] = useState(false);

  useNavigationListener();

  const redirectListPage = () => {
    navigate('/writing-instruction');
  };

  const clickCreateInstruction = async () => {
    const writingInstructionsApi = await getApi(WritingInstructionApi);
    setLoadingSave(true);

    const instructionData = {
      title: instructionState.data.title,
      description: instructionState.data.description,
      userInstruction: instructionState.data.userInstruction,
      detailInstruction: instructionState.data.detailInstruction,
      examples: instructionState.data.examples,
    };

    try {
      const response = instructionState.isEdit
        ? await writingInstructionsApi.updateWritingInstruction(
            instructionState.data.id,
            instructionData,
          )
        : await writingInstructionsApi.createWritingInstruction(
            instructionData,
          );

      if (response.data.id) {
        redirectListPage();
      }
    } catch (error) {
      console.error('Failed to save instruction:', error);
    } finally {
      setLoadingSave(false);
    }
  };

  return (
    <>
      <Paper sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', gap: 4 }}>
          <Box sx={{ width: '70%' }}>
            <Typography variant="h5" sx={{ textAlign: 'left' }}>
              {instructionState.isEdit ? 'Update' : 'Create'} a writing
              instruction template
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mb: 3, textAlign: 'left' }}
            >
              {instructionState.isEdit
                ? 'Updating an existing writing instruction by filling in below forms.'
                : 'Creating a writing instruction by filling in below forms.'}
            </Typography>
            <Typography
              sx={{ mb: 1, textAlign: 'left' }}
              variant="h6"
              component="h2"
            >
              General
            </Typography>
            <TextField
              label="Name"
              variant="outlined"
              placeholder="Input a name"
              fullWidth
              sx={{ mb: 2 }}
              value={instructionState.data.title}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) =>
                dispatch(
                  writingInstructionActions.changeInstructionTitle(
                    e.target.value,
                  ),
                )
              }
              helperText="Maximum 50 characters. Valid characters: A-Z, a-z, 0-9, -, _"
            />
            <TextField
              label="Description (Optional)"
              placeholder="Input a description"
              variant="outlined"
              fullWidth
              sx={{ mb: 1 }}
              value={instructionState.data.description}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) =>
                dispatch(
                  writingInstructionActions.changeInstructionDescription(
                    e.target.value,
                  ),
                )
              }
              helperText="This description appears on instruction template list page. It can help user identify the purpose of the writing instruction."
            />
          </Box>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Typography
            sx={{ mb: 1, textAlign: 'left' }}
            variant="h6"
            component="h2"
          >
            Instructions
          </Typography>
          <Typography variant="body2" sx={{ mb: 2, textAlign: 'left' }}>
            Below values will be used to construct the prompt for invoking LLM.
          </Typography>
          <RichTextEditor />
          <TextField
            label="Details writing guidelines"
            variant="outlined"
            placeholder="Input guidelines"
            fullWidth
            sx={{ mt: 3, mb: 2 }}
            value={instructionState.data.detailInstruction}
            InputLabelProps={{
              shrink: true,
            }}
            multiline
            rows={10}
            onChange={(e) =>
              dispatch(
                writingInstructionActions.changeDetailInstruction(
                  e.target.value,
                ),
              )
            }
            helperText="User {} to encapsulate the placeholder values"
          />

          <List>
            {instructionState.data.examples.map((item, index) => {
              return (
                <Box
                  key={identity(index)}
                  display="flex"
                  gap="10px"
                  alignItems="flex-end"
                  marginTop={2}
                >
                  <TextField
                    label={`Output samples ${index + 1}`}
                    variant="outlined"
                    placeholder="Input output samples"
                    fullWidth
                    value={item}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    multiline
                    rows={3}
                    onChange={(e) => {
                      dispatch(
                        writingInstructionActions.changeInstructionExample({
                          index: index,
                          value: e.target.value,
                        }),
                      );
                    }}
                  />
                  <IconButton
                    onClick={() => {
                      dispatch(
                        writingInstructionActions.removeInstructionExample(
                          index,
                        ),
                      );
                    }}
                    style={{ marginLeft: '8px' }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              );
            })}
          </List>

          {instructionState.data.examples.length < 5 && (
            <Box display="flex" alignItems="center">
              <Button
                startIcon={<AddIcon />}
                onClick={() => {
                  dispatch(
                    writingInstructionActions.addNewInstructionExample(''),
                  );
                }}
              >
                Add Sample
              </Button>
              <Typography
                textAlign="left"
                variant="caption"
                color="rgba(0, 0, 0, 0.6)"
              >
                (Upper limit 5 output samples.)
              </Typography>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'space-start',
          }}
        >
          <LoadingButton
            disabled={!instructionState.data.title}
            loading={loadingSave}
            variant="contained"
            color="primary"
            sx={{ width: '99px' }}
            onClick={() => {
              clickCreateInstruction();
            }}
          >
            {instructionState.isEdit ? 'Update' : 'Save'}
          </LoadingButton>
          <Button onClick={redirectListPage} sx={{ ml: 1, width: '99px' }}>
            Cancel
          </Button>
        </Box>
      </Paper>
    </>
  );
};
